<template>
  <div class="uploader">
      <!-- If model is init -->
      <b-container v-if="this.isModelInit">
        <!-- Not uploaded image file -->
        <div v-if="!image"> 
            <b-form-file 
                id="formFile"
                type="file" 
                @change="onFileChange" 
                placeholder="Add your sketch!" 
                drop-placeholder="Drop your sketch..."
            />
        </div>

        <!-- Already uploaded image file -->
        <div v-else>
            <b-row>
                <b-col>
                    <b-button size="lg" @click="removeImage" variant="danger" block>Undo</b-button>
                </b-col>

                <b-col>
                    <b-button size="lg" variant="success" @click="predict" block>Color</b-button>
                </b-col>
            </b-row>
            <hr>
            <b-row>
                <b-col>
                    <img id="toConvertImage" :width="this.imageSize" :height="this.imageSize" thumbnail :src="image" />
                </b-col>
            </b-row>
            <!-- If prediction is done -->
            <b-row>
                <b-col>
                    <canvas id="predictionCanvas" :width="this.imageSize" :height="this.imageSize"></canvas>
                </b-col>
            </b-row>



        </div>
      </b-container>
      
      <!-- If model is not init -->
      <b-container v-else>
          Loading model...
      </b-container>
  </div>
</template>

<script>
/* eslint-disable */
import * as tf from '@tensorflow/tfjs'

export default {
    name: "Uploader",
    data() {
        return {
            image: '',
            tensorImage: null,
            model: null,
            predictionResult: null,
            predictionIsDone: false,
            imageSize: 128,
            isModelInit: false,
        }
    },
    created: async function() {
        // Init model from url
        // const MODEL_URL = 'https://pastello.rodolfopietro.it/generator_5_128x128_mobile/model.json'
        const MODEL_URL = 'generator_5_128x128_mobile/model.json';
        const model = await tf.loadGraphModel(MODEL_URL);
        this.model = model;

        // Model is initialized
        this.isModelInit = true;
    },
    methods: {
        /**
         * Functions used for process the file
         */
        processFile(event) {
            this.imageToUpload = event.target.files[0]
            console.log(this.imageToUpload)
        },
        onFileChange(e) {
            var files = e.target.files || e.dataTransfer.files;
            if (!files.length)
                return;
            this.createImage(files[0]);
        },
        createImage(file) {
            var image = new Image();
            var reader = new FileReader();
            var vm = this;

            reader.onload = (e) => {
                vm.image = e.target.result;
            };
            reader.readAsDataURL(file);
        },
        removeImage: function(e) {
            this.image = '';
        },
        /**
         * Prediction function
         */
        async predict(){
            // Create tensor by image charged by file
            const image = document.getElementById("toConvertImage")
            this.tensorImage = tf.browser.fromPixels(image);

            // Preprocessing image to predict
            this.tensorImage = tf.cast(this.tensorImage, 'float32')
            // (tensor / 127) -1
            this.tensorImage = tf.sub(
                tf.div(this.tensorImage, 127.5), 
                1
            )

            // Predict image
            this.predictionResult = this.model.predict(tf.expandDims(this.tensorImage, 0))
            this.predictionResult = tf.squeeze(this.predictionResult)

            // Init canvas used to
            var canvas = document.getElementById("predictionCanvas");
            var context = canvas.getContext('2d');

            // Denormalize  and cast to int prediction
            // (vector+1) * 127.5
            this.predictionResult = tf.mul(
                tf.add(this.predictionResult, 1),
                127.5
            )
            this.predictionResult = tf.cast(this.predictionResult, 'int32')

            // Finally draw prediction!
            tf.browser.toPixels(this.predictionResult, canvas)
        }
    }
}
</script>

<style>
</style>